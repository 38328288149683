<template>
  <section class="p-4 mt-4 bg-light border-top">
    <div class="container">
      <div class="row pt-3">
        <div
          class="col-lg-4 col-md-6 mb-2"
          v-for="project in getProjects"
          v-bind:key="project._id"
        >
          <ProjectCard :project="project" />
        </div>
      </div>
      <router-link class="" :to="{ name: 'projects' }">
        <button
          type="button"
          class="btn btn-primary btn-lg px-4 me-md-2 w-100 align-text-bottom mt-5 text-white"
        >
          View More Projects
        </button>
      </router-link>
    </div>
  </section>
</template>

<script>
import ProjectCard from "@/components/ProjectCard.vue";

export default {
  name: "ProjectSection",
  components: {
    ProjectCard,
  },
  data: function () {
    return {};
  },
  created() {
    this.$store.dispatch("resetPagination");
    this.$store.commit("setFilter", '{"featured": true, "_state": true}');
    this.$store.dispatch("fetchProjects");
  },
  computed: {
    getProjects() {
      return this.$store.getters.getProjects;
    },
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-img-top {
  border-radius: 3px;
}
</style>
