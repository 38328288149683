<template>
  <section class="pt-4 mt-4 bg-light border-top">
    <div class="container" v-if="typeof project.name != 'undefined'">
      <h1>{{ project.name }}</h1>
      <h3>{{ project.sub }}</h3>
      <div class="row pt-3">
        <div class="col-lg-9 col-md-12">
          <!-- Dit is het linker gedeelte -->
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <ImageCarouselModal :project="project" />
              <div
                class="show-zoom"
                data-bs-toggle="modal"
                data-bs-target="#imageCarouselModal"
              >
                <ImageCarousel :project="project" class="mb-4" />
              </div>
            </div>
            <div class="col-lg-6 d-lg-block">
              <!-- Dit is de rechter helft van het linker gedeelte (1/2), alleen zichtbaar op xl en l schermen -->
              <!-- Hier komt de inhoud van de rechter helft -->
              <div v-html="project.lead" class="lead pb-4"></div>
            </div>
          </div>

          <!-- Content -->
          <div class="row border-top pt-3" v-if="project.content.length > 0">
            <div
              class="col-lg-6 col-md-12"
              v-for="content in project.content"
              v-html="content"
              v-bind:key="content"
            ></div>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 mb-5">
          <!-- Dit is het rechter gedeelte (1/3) -->
          <!-- Hier komt de inhoud van het rechter gedeelte -->
          <div class="d-grid gap-2 pb-4" v-if="hasProjectButtons">
            <a
              v-for="button in project.buttons"
              v-bind:key="button"
              type="button"
              class="btn btn-lg text-white"
              :class="button.color"
              :href="button.url"
              :target="button.target"
            >
              <i class="bi" :class="button.icon"></i>
              {{ button.name }}
            </a>
          </div>
          <h4>Tags</h4>
          <router-link
            v-for="(tag, index) in project.tags"
            v-bind:key="tag"
            :to="{ name: 'tag', params: { tag: tag } }"
            class="text-secondary"
          >
            {{ tag }}<span v-if="index < project.tags.length - 1">, </span>
          </router-link>
          <ShareButton :project="project" class="pt-3" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import ShareButton from "@/components/ShareButton.vue";
import ImageCarousel from "@/components/ImageCarousel.vue";
import ImageCarouselModal from "@/components/ImageCarouselModal.vue";

export default {
  name: "ProjectView",
  components: {
    ShareButton,
    ImageCarousel,
    ImageCarouselModal,
  },
  computed: {
    hasProjectButtons() {
      if (this.project.buttons) {
        if (this.project.buttons.length > 0) {
          return true;
        }
      }
      return false;
    },
  },
  data: function () {
    return {
      loading: true,
      project: {},
    };
  },
  created() {
    this.fetchProject();
  },
  methods: {
    fetchProject() {
      // console.log(this.$route.params.slug);
      axios
        .get("https://db.sephendevos.com/api/content/item/projects", {
          params: {
            limit: 1,
            filter: '{"slug": "' + this.$route.params.slug + '",}',
          },
          headers: {
            "api-key": "API-6d186ac2fe585990db202c32ae770c66fd8ad191",
          },
        })
        .then((response) => {
          // console.log(response);
          this.project = response.data;
          document.title = "Sephen de Vos - " + this.project.name;
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },
  },
  beforeRouteLeave(to, from, next) {
    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) {
      backdrop.parentNode.removeChild(backdrop);
    }

    const modalElement = document.querySelector(".modal");
    if (modalElement) {
      modalElement.classList.add("modal-close");
      modalElement.classList.remove("modal-open");
    }

    // Remove .modal-open from body to re-enable scrolling
    document.body.classList.remove("modal-open");

    // Ensure scrolling is enabled
    document.body.style.overflow = "auto";

    next();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.show-zoom {
  cursor: zoom-in;
}
</style>
