<template lang="html">
  <div class="d-grid">
    <div class="dropdown">
      <button
        class="btn btn-secondary dropdown-toggle w-100"
        type="button"
        id="shareDropdownMenu"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="bi bi-share"></i>
        Share
      </button>
      <ul class="dropdown-menu w-100" aria-labelledby="shareDropdownMenu">
        <li v-for="share in sharing" v-bind:key="share">
          <a class="dropdown-item" :href="getUrl(share)" target="_blank">
            <i class="bi" :class="share.icon"></i>
            {{ share.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ShareButton",
  props: {
    project: Object,
  },
  data: function () {
    return {
      loading: true,
      sharing: [],
    };
  },
  created() {
    this.fetchSharing();
  },
  methods: {
    fetchSharing() {
      axios
        .get("https://db.sephendevos.com/api/content/item/sharing", {
          params: {},
          headers: {
            "api-key": "API-6d186ac2fe585990db202c32ae770c66fd8ad191",
          },
        })
        .then((response) => {
          // console.log(response.data.channel);
          this.sharing = response.data.channel;
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },
    getUrl(share) {
      let baseUrl = share.channel_url;
      let params = [];
      if (share.param_url) {
        params[share.param_url] = window.location.href;
      }
      if (share.param_title) {
        params[share.param_title] = this.project.name;
      }
      if (share.param_description) {
        params[share.param_description] = this.project.lead;
      }
      if (share.param_media) {
        params[share.param_media] =
          "https://db.sephendevos.com/storage/uploads/" +
          this.project.image[0].path;
      }
      return this.createUrl(baseUrl, params);
    },
    createUrl(baseUrl, params) {
      const nonEmptyParams = {};

      // filter out empty params
      for (const key in params) {
        if (
          params[key] !== "" &&
          params[key] !== null &&
          params[key] !== undefined
        ) {
          nonEmptyParams[key] = params[key];
        }
      }

      const queryString = Object.keys(nonEmptyParams)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(
              nonEmptyParams[key]
            )}`
        )
        .join("&");

      return `${baseUrl}${queryString ? `?${queryString}` : ""}`;
    },
  },
};
</script>

<style lang="css" scoped></style>
