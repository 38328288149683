import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import Qs from "qs";
import VueGtag from "vue-gtag";

// import "bootstrap/scss/bootstrap.scss";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";

// Format nested params correctly
axios.interceptors.request.use((config) => {
  config.paramsSerializer = (params) => {
    // Qs is not included in the Axios package
    return Qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false,
    });
  };
  return config;
});

createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(VueGtag, {
    config: { id: "G-1BKZ9FT7D2" },
  })
  .mount("#app");
