<template>
  <section class="p-4 mt-4 bg-light border-top">
    <div class="container" v-if="tag">
      <h1>
        Projects <small>({{ tag }})</small>
      </h1>
      <div class="row pt-3">
        <div
          class="col-lg-4 col-md-6 mb-4"
          v-for="project in getProjects"
          v-bind:key="project._id"
        >
          <ProjectCard :project="project" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProjectCard from "@/components/ProjectCard.vue";

export default {
  name: "ProjectsView",
  components: {
    ProjectCard,
  },
  data: function () {
    return {
      tag: null,
    };
  },
  created() {
    this.$store.dispatch("resetPagination");
    this.fetchProjects();
  },
  computed: {
    getProjects() {
      return this.$store.getters.getProjects;
    },
  },
  methods: {
    fetchProjects() {
      this.tag = this.$route.params.tag;
      this.$store.commit(
        "setFilter",
        '{"tags": "' + this.tag + '", "_state": true}'
      );
      document.title = "Sephen de Vos - Projects (" + this.tag + ")";
      this.$store.dispatch("fetchProjects");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-img-top {
  border-radius: 3px;
}
</style>
