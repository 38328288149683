<template>
  <nav v-if="totalPages > 1">
    <ul class="pagination pagination-lg justify-content-center">
      <li class="page-item" :class="{ disabled: currentPage <= 1 }">
        <a
          class="page-link"
          href="#"
          @click.prevent="changePage(currentPage - 1)"
          onclick="this.blur();"
        >
          <i class="bi bi-chevron-left"></i>
        </a>
      </li>
      <li
        v-for="pageNumber in totalPages"
        :key="pageNumber"
        class="page-item"
        :class="{ active: pageNumber === currentPage }"
      >
        <a
          class="page-link"
          href="#"
          @click.prevent="changePage(pageNumber)"
          :class="{ 'text-white': pageNumber === currentPage }"
          onclick="this.blur();"
        >
          {{ pageNumber }}
        </a>
      </li>
      <li class="page-item" :class="{ disabled: currentPage >= totalPages }">
        <a
          class="page-link"
          href="#"
          @click.prevent="changePage(currentPage + 1)"
          onclick="this.blur();"
        >
          <i class="bi bi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "PaginationNav",
  computed: {
    totalPages() {
      let items = this.$store.getters.getTotalItems;
      let limit = this.$store.getters.getLimit;
      if (limit < items) {
        return Math.ceil(items / limit);
      }
      return 1;
    },
    currentPage() {
      let limit = this.$store.getters.getLimit;
      let skip = this.$store.getters.getSkip;
      let page = skip / limit;
      return page + 1;
    },
  },
  methods: {
    changePage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        let limit = this.$store.getters.getLimit;
        let skip = (pageNumber - 1) * limit;
        this.$store.commit("setSkip", skip);
        this.$store.dispatch("fetchProjects");
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>

<style scoped></style>
